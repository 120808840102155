var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mt-5",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"title-section"},[_vm._v(" FINANZAS ")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-icon',{attrs:{"color":"black","large":""}},[_vm._v("mdi-chart-box-outline")]),_c('b',[_vm._v("Estados Financieros")])],1),_c('v-col',{attrs:{"cols":"4"}},[(_vm.isAdmin)?_c('v-btn',{staticClass:"normal-btn",attrs:{"color":"primary","depressed":""},on:{"click":_vm.showCreateDialog}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" Crear Estado Financiero ")],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"4"}},[_c('kimsa-text-field',{attrs:{"placeholder":"Buscar","append-icon":"mdi-magnify","outlined":"","dense":"","solo":"","flat":""},on:{"change":function($event){_vm.search = $event}}})],1)],1),_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.financials,"search":_vm.search,"items-per-page":5},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-account-circle-outline")]),_c('b',{domProps:{"textContent":_vm._s(item.client)}})]}},{key:"item.year",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-calendar-outline")]),_vm._v(" "+_vm._s(item.year)+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-account-outline")]),_vm._v(" "+_vm._s(item.user)+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-clock-outline")]),_vm._v(" "+_vm._s(item.updated_at)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1 my-1",attrs:{"fab":"","outlined":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mr-1 my-1",attrs:{"fab":"","outlined":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-delete")])],1),_c('v-btn',{staticClass:"my-1",attrs:{"fab":"","outlined":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showPdfDownload(item)}}},[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-eye")])],1)]}}])}),_c('create-financial-dialog',{ref:"creator",on:{"success":_vm.loadFinancials}}),_c('financial-pdf-download',{ref:"downloadpdf"}),_c('kimsa-confirm',{ref:"confirm",attrs:{"title":"Eliminar","content":"¿Esta seguro que desea eliminar este Estado Financiero?","persistent":"","loading":_vm.loadingDelete,"no-close-on-confirm":""},on:{"confirm":_vm.onConfirmDelete,"cancel":function($event){_vm.currentId = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }