<template>
    <v-container>

        <!-- HEADER -->
        <v-row class="mt-5" align="center">
            <v-col cols="12">
                <div class="title-section">
                    FINANZAS
                </div>
            </v-col>
            <v-col cols="4">
                <v-icon color="black" large>mdi-chart-box-outline</v-icon>
                <b>Estados Financieros</b>
            </v-col>
            <v-col cols="4">
                <v-btn v-if="isAdmin" class="normal-btn" color="primary" depressed @click="showCreateDialog">
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    Crear Estado Financiero
                </v-btn>
            </v-col>
            <v-col cols="4">
                <kimsa-text-field
                    placeholder="Buscar"
                    append-icon="mdi-magnify"
                    outlined dense solo flat
                    @change="search = $event"
                />
            </v-col>
        </v-row>

        <!-- TABLE -->
        <v-data-table
            class="mt-5"
            :headers="headers"
            :loading="loading"
            :items="financials"
            :search.sync="search"
            :items-per-page="5"
        >
            <template v-slot:item.client="{ item }">
                <v-icon>mdi-account-circle-outline</v-icon>
                <b v-text="item.client"></b>
            </template>
            <template v-slot:item.year="{ item }">
                <v-icon>mdi-calendar-outline</v-icon>
                {{ item.year }}
            </template>
            <template v-slot:item.user="{ item }">
                <v-icon>mdi-account-outline</v-icon>
                {{ item.user }}
            </template>
            <template v-slot:item.updated_at="{ item }">
                <v-icon>mdi-clock-outline</v-icon>
                {{ item.updated_at }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn class="mr-1 my-1" fab outlined x-small @click.stop="editItem(item)">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="mr-1 my-1" fab outlined x-small @click.stop="deleteItem(item)">
                    <v-icon color="primary">mdi-delete</v-icon>
                </v-btn>
                <v-btn class="my-1" fab outlined x-small @click.stop="showPdfDownload(item)">
                    <v-icon color="info">mdi-eye</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <!-- COMPONENTS -->
        <create-financial-dialog @success="loadFinancials" ref="creator" />
        <financial-pdf-download ref="downloadpdf"/>

        <kimsa-confirm
            title="Eliminar"
            content="¿Esta seguro que desea eliminar este Estado Financiero?"
            persistent
            @confirm="onConfirmDelete"
            @cancel="currentId = null"
            :loading="loadingDelete"
            no-close-on-confirm
            ref="confirm"
        />

    </v-container>
</template>

<script>
import Axios from "@/utils/Axios";
import KimsaConfirm from "@/components/common/KimsaConfirm";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import CreateFinancialDialog from "@/components/financial/CreateFinancialDialog";
import FinancialPdfDownload from "@/components/financial/FinancialPdfDownload";

export default {
    name: "Financial",
    components: {FinancialPdfDownload, CreateFinancialDialog, KimsaTextField, KimsaConfirm},
    data() {
        return {
            loading: false,
            financials: [],
            search: '',
            currentId: null,
            loadingDelete: false,
            headers: [
                {text: 'Cliente', value: 'client', class: 'grey white--text'},
                {text: 'Año', value: 'year', class: 'grey white--text'},
                {text: 'Autor', value: 'user', class: 'grey white--text'},
                {text: 'Actualización', value: 'updated_at', class: 'grey white--text'},
                {text: '', value: 'actions', sortable: false, class: 'grey'},
            ],
            loadingPDF: false,
        }
    },
    async mounted() {
        const vm = this
        await vm.loadFinancials()
    },
    methods: {
        async loadFinancials() {
            const vm = this
            vm.loading = true

            if(!vm.isAdmin) vm.headers.pop()

            await Axios.post('financial-statements').then(res => {
                // console.log('res loadFinancials',res)
                vm.financials = res.data.result.financialStatements.map(financial => {
                    return {
                        id: financial.id,
                        client: financial.client.name,
                        year: financial.year,
                        user: financial.user.name,
                        created_at: vm.$moment(financial.created_at).format('DD/MM/YYYY'),
                        updated_at: vm.$moment(financial.updated_at).format('DD/MM/YYYY'),
                    }
                })
            }).catch(er => {
                console.log('error loadFinancials', er)
            }).finally(() => {
                vm.loading = false
            })
        },
        formatPeriod(period) {
            const vm = this
            let periodString = vm.$moment(period).format('MMMM YYYY')
            return periodString.charAt(0).toUpperCase() + periodString.slice(1);
        },
        showCreateDialog() {
            const vm = this
            vm.$refs.creator.show()
        },
        editItem(item) {
            const vm = this
            vm.$refs.creator.loadFinancial(item.id)
        },
        deleteItem(item) {
            const vm = this
            vm.$refs.confirm.show()
            vm.currentId = item.id
        },
        showPdfDownload(item) {
            const vm = this
            console.log('item',item)
            vm.$refs.downloadpdf.show(item)
            vm.currentId = item.id
        },
        async onConfirmDelete() {
            const vm = this
            vm.loadingDelete = true

            await Axios.delete(`financial-statement/${vm.currentId}/delete`).then(res => {
                // console.log('res onConfirmDelete',res)
                vm.toast('Estado financiero eliminado')
            }).catch(er => {
                console.log('er',er)
                vm.toast('error onConfirmDelete', er)
            })

            vm.loadingDelete = false
            vm.currentId = null

            vm.$refs.confirm.hide()
            await vm.loadFinancials()
        },
    }
}
</script>

<style scoped>

.title-section {
    color: var(--v-primary-base);
}

</style>